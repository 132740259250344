import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { SharedService } from '../../layouts/shared-service';
import { AnalysisDetails, DropDown, SearchResult } from '../../model/analysis-details.model';
import { DropDownService } from '../../service/drop-down.service';
import { AuthService, DBService } from '../../service';
import { User } from '../../model/types';
import { Router } from '@angular/router';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER, SPACE, } from '@angular/cdk/keycodes';
import moment from 'moment';
import { AuthNewService } from '../../service/auth-new.service';
import { delay } from 'rxjs/operators';



@Component({
  selector: 'app-new-analysis',
  templateUrl: './new-analysis.component.html',
  styleUrls: ['./new-analysis.component.scss']
})
export class NewAnalysisComponent implements OnInit {



  pageTitle = 'Analyze Report/Data';
  signedInUser: User;
  analysisFormGroup: FormGroup;
  analysisFormGroup_ADA: FormGroup;
  analysisFormGroup_RSG: FormGroup;
  analysisFormGroup_AP: FormGroup;
  lts80_dialog: FormControl = new FormControl();
  visible = true;
  selectable = true;
  removable = true;
  values = [];

  // analytes: FormArray
  analysisDetails: AnalysisDetails;

  post: any = '';

  // pattern1 = '^([a-zA-Z0-9\\s]+[-&/])*([a-zA-Z0-9\\s])+?$';
  pattern1 = '^([a-zA-Z0-9., :-])*([a-zA-Z0-9., :-])+?$';
  stdConc_pattern = '^[-]?[0-9]+([,.][0-9]+)?$';
  stdConc_validator: boolean;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

  Tags: string[] = [];
  maxDate;
  multipleAnalyteError = false;

  addOnBlur = true;
  // optionsSACodes = [];
  // optionsMVCodes = [];
  // filteredOptionsSA: Observable<string[]>;
  // filteredOptionsMV: Observable<string[]>;


  constructor(private formBuilder: FormBuilder,
    private _sharedService: SharedService,
    private authService: AuthNewService,
    private router: Router,
    private dbService: DBService,
    private dropDownService: DropDownService,
    private newAuthService: AuthNewService,
    private ref: ChangeDetectorRef) {

    this._sharedService.emitChange(this.pageTitle);
  }

  ngOnInit() {
    const currentDate = moment().toDate();
    this.maxDate = currentDate;
    this.newAuthService.getCurrentUser();
    this.newAuthService.getCurrentLoginUser.pipe(delay(1000)).subscribe((user: any) => {
      // console.log(user);
      this.signedInUser = user;
      if (!this.signedInUser) {
        this.router.navigate(['/extra-layout/signin']);
        return;
      }
    });

    this.createForm();
    // console.log(this.Groups);
  }


  createForm() {
    this.analysisFormGroup = this.formBuilder.group({
      analysisType: [null, Validators.required],
      analysisSubtype: [null],

      projectCodeSA: ['', [Validators.maxLength(50)]],
      projectCodeMV: [null],
      regressionModel: [null],
      lloq: [null],
      uloq: [null],
      re_type: [true],
      re_value: [null],
      lts80_dialog: [null, [Validators.maxLength(10)]],
      lts20_dialog: [null],
      extraction_dialog: [null, [Validators.maxLength(10)]],
      benchtop_dialog: [null, [Validators.maxLength(10)]],
      freeze_thaw_dialog: [null, [Validators.pattern('^[0-9]+([.][0-9]+)?$'), Validators.maxLength(3)]],
      multipleAnalytes: ['No'],
      first_patient_dialog: [null],
      template: [''],
      sampleMatrix: ['', [Validators.pattern('[a-zA-Z][a-zA-Z ]+'), Validators.maxLength(50)]],
      analysisSubtype_ADA: [null],
      antiCoagulant: ['', [Validators.pattern('^[A-Za-z0-9? ,_-]+$'), Validators.maxLength(50)]],
      species: ['', [Validators.pattern('[a-zA-Z][a-zA-Z ]+'), Validators.maxLength(50)]],
      samplePrep: [null],
      sampleDate: ['', [Validators.max(this.maxDate)]],

      analyteNames: this.formBuilder.array([this.formBuilder.control([], Validators.maxLength(100))]),
    });

    this.analysisFormGroup_ADA = this.formBuilder.group({
      analysisType: ['ADA'],
      analysisSubtype: [null],
      ada_subtype: [null],
      analyteNames: this.formBuilder.array([this.formBuilder.control([])]),
      projectCodeSA: [''],
      sampleMatrix: ['', [Validators.pattern('^[a-zA-Z]*$')]],
      species: ['', [Validators.pattern('^[a-zA-Z]*$')]],
      tukeyCoefficient: this.formBuilder.array([]),
      normalityTestData: [null],
      screening_cutpoint: [''],
      confirmatory_cutpoint: [null],
      concentrationUnit: [null],
      // negativeControlValues: ['Yes'],
      regressionType: [''],
      nabDataset: ['No'],
      CVThreshold: ['20'],
      PL: ['4pl']
    })

    this.analysisFormGroup_AP = this.formBuilder.group({
      analyteNames: this.formBuilder.array([this.formBuilder.control([], Validators.pattern(this.pattern1))]),
      analysisType: ['AP'],
      projectCode_AP: ['', [Validators.pattern(this.pattern1), Validators.maxLength(50), Validators.required]],
      projectCodeSA: '',
      analysisSubtype: [null]
    })

    this.analysisFormGroup_RSG = this.formBuilder.group({
      analyteNames: this.formBuilder.array([this.formBuilder.control([], Validators.maxLength(100))]),
      STD_conc_arr: this.formBuilder.array([this.formBuilder.control([], Validators.pattern('^[-]?[0-9]+([,.][0-9]+)?$'))]),
      analysisType: ['RSG'],
      analysisSubtype: [null],
      report_template: [null],
      weighting_factor: ['None', [Validators.pattern('^[a-zA-Z0-9]*([/^+-])*[a-zA-Z0-9]*([/^+-])*[a-zA-Z0-9]*$'), Validators.maxLength(50)]],
      projectCodeSA: ['', [Validators.pattern(this.pattern1), Validators.maxLength(50)]],
      regressionModel: ['', [Validators.pattern('^[a-zA-Z0-9]*$')]],
      antiCoagulant: ['None', [Validators.pattern('^[a-zA-Z0-9\\s]+[a-zA-Z0-9\\s]*$'), Validators.maxLength(50)]],
      species: ['None', [Validators.pattern('^[a-zA-Z\\s]+[a-zA-Z\\s]*$'), Validators.maxLength(50)]],
      sampleMatrix: ['None', [Validators.pattern('^[a-zA-Z\\s]+[a-zA-Z\\s]*$'), Validators.maxLength(50)]],
      conc_unit: ['None', [Validators.pattern('.*'), Validators.maxLength(10)]],
      lloq: ['0.00', [Validators.pattern('^[-]?[0-9]+([,.][0-9]+)?$'), Validators.maxLength(50)]],
      uloq: ['0.00', [Validators.pattern('^[-]?[0-9]+([,.][0-9]+)?$'), Validators.maxLength(50)]],
      benchTop_Temp: ['None', [Validators.pattern(this.pattern1), Validators.maxLength(100)]],
      noOfcycles: ['000', [Validators.pattern('^[0-9]*$'), Validators.maxLength(3)]],
      benchtop_hours: ['000', [Validators.pattern('^[-]?[0-9]+([,.][0-9]+)?$'), Validators.maxLength(10)]],
      frozen_temp: ['000', [Validators.pattern('^[-]?[0-9]+([,.][0-9]+)?$'), Validators.maxLength(50)]],
      longTerm_frozen_temp: ['000', [Validators.pattern('^[-]?[0-9]+([,.][0-9]+)?$'), Validators.maxLength(50)]],
      longTerm_storage_days: ['000', [Validators.pattern('^[0-9]*$'), Validators.maxLength(10)]],
      protocol_id: ['None', [Validators.pattern('^([a-zA-Z0-9\\s]+[-.,])*([a-zA-Z0-9\\s])+[.]?$'), Validators.maxLength(50)]],
      thawing_temp: ['None', [Validators.pattern(this.pattern1), Validators.maxLength(100)]],
      anchor_point_1: ['000', [Validators.pattern('^[-]?[0-9]+([,.][0-9]+)?$'), Validators.maxLength(50)]],
      anchor_point_2: ['000', [Validators.pattern('^[-]?[0-9]+([,.][0-9]+)?$'), Validators.maxLength(50)]],
      Dilution_QC_conc: ['000', [Validators.pattern('^[-]?[0-9]+([,.][0-9]+)?$'), Validators.maxLength(50)]],
      sample_volume: ['000', [Validators.pattern('^[0-9]*$'), Validators.maxLength(50)]],
      report_id: ['None', [Validators.pattern(this.pattern1), Validators.maxLength(50)]],
      method_id: ['None', [Validators.pattern(this.pattern1), Validators.maxLength(50)]],
      concomitant_name: ['None', [Validators.maxLength(50)]],
      comed_conc: ['0.00', [Validators.pattern('^[-]?[0-9]+([,.][0-9]+)?$'), Validators.maxLength(50)]],
      method_type: ['None', [Validators.maxLength(100)]],
      Low_QC_conc: ['000', [Validators.pattern('^[-]?[0-9]+([,.][0-9]+)?$'), Validators.maxLength(50)]],
      Mid_QC_conc: ['000', [Validators.pattern('^[-]?[0-9]+([,.][0-9]+)?$'), Validators.maxLength(50)]],
      High_QC_conc: ['000', [Validators.pattern('^[-]?[0-9]+([,.][0-9]+)?$'), Validators.maxLength(50)]],
      sponsor_name: ['None', [Validators.maxLength(100)]],
      val_project_code: ['', [Validators.pattern('^([a-zA-Z0-9\\s]+[-.,])*([a-zA-Z0-9\\s])+?$'), Validators.maxLength(50)]],
      sample_temp: ['000', [Validators.pattern('^[-]?[0-9]+([,.][0-9]+)?$'), Validators.maxLength(50)]],
      collection_date: ['', [Validators.max(this.maxDate)]],
      MRD: ['000', [Validators.pattern('^[0-9]+([,.][0-9]+)?$'), Validators.maxLength(50)]]
    });
    this.ref.detectChanges();
  }


  //   isValidInput(fieldName): boolean {
  //     return this.analysisFormGroup.controls[fieldName].invalid &&
  //       (this.analysisFormGroup.controls[fieldName].dirty || this.analysisFormGroup.controls[fieldName].touched);
  // }
  // isValidInput2(fieldName): boolean {
  //   return this.analysisFormGroup_ADA.controls[fieldName].invalid &&
  //     (this.analysisFormGroup_ADA.controls[fieldName].dirty || this.analysisFormGroup_ADA.controls[fieldName].touched);
  // }
  // isValidInput3(fieldName): boolean {
  //   return this.analysisFormGroup_RSG.controls[fieldName].invalid &&
  //     (this.analysisFormGroup_RSG.controls[fieldName].dirty || this.analysisFormGroup_RSG.controls[fieldName].touched);
  // }

  get formControls() { return this.analysisFormGroup.controls; }

  get formControls1() { return this.analysisFormGroup_ADA.controls; }

  get formControlsAP() { return this.analysisFormGroup_AP.controls; }


  get analyteNames() {

    // tslint:disable-next-line: max-line-length
    if (this.analysisFormGroup.value.analysisType === 'SMR' || this.analysisFormGroup.value.analysisType === 'LMR' || this.analysisFormGroup.value.analysisType === 'CHR') {
      return this.analysisFormGroup.get('analyteNames') as FormArray;
    } else if (this.analysisFormGroup.value.analysisType === 'ADA') {
      return this.analysisFormGroup_ADA.get('analyteNames') as FormArray;
    } else if (this.analysisFormGroup.value.analysisType === 'RSG') {
      return this.analysisFormGroup_RSG.get('analyteNames') as FormArray;
    } else if (this.analysisFormGroup_AP.value.analysisType === 'AP') {
      return this.analysisFormGroup_AP.get('analyteNames') as FormArray;
    }
  }

  get STD_conc_arr() {
    return this.analysisFormGroup_RSG.get('STD_conc_arr') as FormArray;
  }


  addAnalyte() {
    this.analyteNames.push(this.formBuilder.control('', Validators.pattern(this.pattern1)));
  }

 
  addAnalyte_RSG() {
    this.STD_conc_arr.push(this.formBuilder.control('', Validators.pattern('^[-]?[0-9]+([,.][0-9]+)?$')));
  }

  // remove Analyte from group
  removeAnalyte(index) {
    // this.analyteNames = this.form.get('analyteNames') as FormArray;
    this.analyteNames.removeAt(index);
  }

  removeAnalyte_RSG(index) {
    this.STD_conc_arr.removeAt(index);
  }

  onSubmit(post) {
    console.log('Submit for Analysis')
    // tslint:disable-next-line: max-line-length
    if (this.analysisFormGroup.value.analysisType === 'SMR' || this.analysisFormGroup.value.analysisType === 'LMR' || this.analysisFormGroup.value.analysisType === 'CHR') {
      this.analysisDetails = Object.assign({}, this.analysisFormGroup.value);
      // console.log('object value', this.analysisDetails);
      if (this.analysisDetails.multipleAnalytes === 'Yes') {
        this.analysisDetails.multipleAnalytes = 'True'
      } else {
        this.analysisDetails.multipleAnalytes = 'False'
      }

    } else if (this.analysisFormGroup.value.analysisType === 'ADA') {
      this.analysisDetails = Object.assign({}, this.analysisFormGroup_ADA.value);
      console.log(this.analysisDetails);
    } else if (this.analysisFormGroup.value.analysisType === 'RSG') {
      this.analysisDetails = Object.assign({}, this.analysisFormGroup_RSG.value);
    } else if (this.analysisFormGroup_AP.value.analysisType === 'AP') {
      this.analysisDetails = Object.assign({}, this.analysisFormGroup_AP.value);
      console.log(this.analysisDetails);
    }
    this.analysisDetails.userId = this.signedInUser.username;
    this.analysisDetails.analysisId = (new Date).getTime().toString();
    this.post = post;
  }


  resetAnalysis() {
    console.log('RESET CAlled');
  }

  // function to check the multiple values checked or not.
  multipleAnalyteValues() {
    const multipleChecked = this.analysisFormGroup.value.multipleAnalytes;
    const count = this.analysisFormGroup.value.analyteNames;
    console.log(multipleChecked);
    console.log(count);

    // tslint:disable-next-line: triple-equals
    if (multipleChecked === 'True' && count.length == 1) {
      console.log('error');
      // tslint:disable-next-line: no-unused-expression
      this.analysisFormGroup.invalid;
    } else {
      console.log('no error');
    }

  }

  getDisplayString(key) {
    return this.dropDownService.nameDescMap.get(key);
  }

  getDropDownValues(key) {
    return this.dropDownService.dropDownsMap.get(key);
  }

  add(event: MatChipInputEvent): void {

    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {

      // this.Tags.push(value);
      const control = this.analysisFormGroup_ADA.controls.tukeyCoefficient as FormArray;
      control.push(this.formBuilder.control(value.trim()));
    }

    if (input) {
      input.value = '';
    }
  }

  // remove(tag: string): void {
  //   const index = this.Tags.indexOf(tag);

  //   if (index >= 0) {

  //     this.Tags.splice(index, 1);
  //   }
  // }
  remove(i: number) {
    const control = this.analysisFormGroup_ADA.controls.tukeyCoefficient as FormArray;
    control.removeAt(i);
  }

  ppssToDisplay() {
    // Simulate your async call to ppssService
    setTimeout(() => {
      const control = this.analysisFormGroup.controls.tukeyCoefficient as FormArray;
    }, 200)
  }




}
