// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.matwidth {
  width: -moz-fit-content;
  width: fit-content;
}

.mat-div {
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/previous-analysis/previous-analysis.component.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EAAA,kBAAA;AACJ;;AAEE;EACE,mBAAA;AACJ","sourcesContent":[".matwidth{\r\n    width: fit-content;\r\n  }\r\n\r\n  .mat-div{\r\n    margin-bottom: 10px;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
