export const environment = {
  production: true,
  serverUrl: 'https://gfizyy4vab.execute-api.us-east-2.amazonaws.com/dev',
  bucket: 'reg-demo-upload',
  userPool: {
    UserPoolId: 'us-east-2_dFLIDhMC1',
    ClientId: 'qfr8jons07au7ql5u1ek6d0id',
    region: 'us-east-2'
  },
  identityPoolId: 'us-east-2:a568f53f-91e3-4619-bc54-df2e416cd62b',

  defaultRegion: 'us-east-2',
    cognito: {
      identityPoolId: 'us-east-2:a568f53f-91e3-4619-bc54-df2e416cd62b', //REQUIRED - Amazon Cognito Identity Pool ID
      region: 'us-east-2', // REQUIRED - Amazon Cognito Region
      userPoolId: 'us-east-2_dFLIDhMC1', //OPTIONAL - Amazon Cognito User Pool ID
      userPoolWebClientId: 'qfr8jons07au7ql5u1ek6d0id', //OPTIONAL - Amazon Cognito Web Client ID
    },
    Storage: {
      AWSS3: {
        bucket: 'reg-demo-upload', //REQUIRED -  Amazon S3 bucket
        region: 'us-east-2', //OPTIONAL -  Amazon service region
      }
    }
};

const awsconfig = {
  'aws_project_region': 'us-east-2',
  'aws_cognito_region': 'us-east-2',
  'aws_user_pools_id': 'us-east-2_dFLIDhMC1',
  'aws_user_pools_web_client_id': 'qfr8jons07au7ql5u1ek6d0id',
  'aws_cognito_mfa_configuration': 'OPTIONAL',

};
export default awsconfig;


