// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fill-remaining-space {
  /* This fills the remaining space, by using flexbox.
     Every toolbar row uses a flexbox row layout. */
  flex: 1 1 auto;
}

.container {
  padding: 10px;
  margin: 20px;
}

.form {
  min-width: 150px;
  max-width: 700px;
  width: 100%;
}

.form-element {
  padding: 5px 0px 25px 2px;
  width: 100%;
}

.button {
  margin: 75px 1px;
}

.error {
  font-size: 12px;
}

.text-danger {
  font-size: smaller;
}

::ng-deep .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/new-analysis/new-analysis.component.scss"],"names":[],"mappings":"AAAA;EACE;mDAAA;EAEA,cAAA;AACF;;AAEA;EACE,aAAA;EACA,YAAA;AACF;;AAEA;EACE,gBAAA;EACA,gBAAA;EACA,WAAA;AACF;;AAEA;EACE,yBAAA;EACA,WAAA;AACF;;AAEA;EAEE,gBAAA;AAAF;;AAGA;EACE,eAAA;AAAF;;AAEA;EACE,kBAAA;AACF;;AAGE;EACE,uBAAA;AAAJ","sourcesContent":[".fill-remaining-space {\r\n  /* This fills the remaining space, by using flexbox.\r\n     Every toolbar row uses a flexbox row layout. */\r\n  flex: 1 1 auto;\r\n}\r\n\r\n.container {\r\n  padding: 10px;\r\n  margin: 20px;\r\n}\r\n\r\n.form {\r\n  min-width: 150px;\r\n  max-width: 700px;\r\n  width: 100%;\r\n}\r\n\r\n.form-element {\r\n  padding: 5px 0px 25px 2px;\r\n  width: 100%;\r\n}\r\n\r\n.button {\r\n\r\n  margin: 75px 1px;\r\n}\r\n\r\n.error {\r\n  font-size: 12px;\r\n}\r\n.text-danger{\r\n  font-size: smaller;\r\n }\r\n\r\n ::ng-deep {\r\n  .mdc-text-field--filled:not(.mdc-text-field--disabled) {\r\n    background-color:white;\r\n}\r\n\r\n}\r\n "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
