// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  width: 100%;
}

.col-md-4 {
  margin-left: auto;
  margin-bottom: 10px;
}

.matwidth {
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 20px;
}

.mat-display {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/auth-log/auth-log.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,iBAAA;EACA,mBAAA;AACF;;AAEA;EACE,uBAAA;EAAA,kBAAA;EACA,kBAAA;AACF;;AAEA;EAEA,aAAA;AAAA","sourcesContent":["table {\r\n  width: 100%;\r\n}\r\n\r\n.col-md-4{\r\n  margin-left: auto;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.matwidth{\r\n  width: fit-content;\r\n  margin-right: 20px;\r\n}\r\n\r\n.mat-display{\r\n\r\ndisplay: flex;\r\n\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
